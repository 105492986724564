<template>
    <v-container fill-height fluid class="pa-0 ma-0 mb-10">
        <v-row class="ma-0 pa-0">
            <v-img :src="require('@/assets/images/evento_4.jpg')"
                   width="100%"
                   height="250"
                   position="bottom"
                   class="mx-0 align-end"
                   transition="slide-x-transition"
                   gradient="to top, rgba(0,0,0,.3), rgba(0,0,0,.5)">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin text-h5 mx-2 text-md-h4 white--text">
                               Centro de Evento y Espectáculo ROSARITO
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row align="center" class="pt-0 pb-1 px-5 px-md-10" justify="center">
            <v-col cols="10" class="justify-center text-center">
                <v-img
                    :src="require('@/assets/images/logo-inmobiliaria1.png')"
                    height="auto"
                    width="400"
                    contain
                    transition="scroll-x-transition">
                </v-img>
            </v-col>
            <v-col cols="10">
                <p>
                    El Centro de Eventos Y Espectáculos Rosarito (CEER) es un espacio multidisciplinario para el
                    desarrollo de eventos masivos con diversas capacidades que permiten llevar a cabo diferentes eventos
                    y brindar una experiencia 360 única a los asistentes.
                </p>
                <p>
                    Este espacio busca generar una solución de recinto para usos múltiples en una ubicación estratégica
                    del municipio de Rosarito, con una excelente conectividad de transporte, servicios gastronómicos, de
                    hotelería, servicios turísticos y entretenimiento.
                </p>
                <p>
                    El Proyecto consiste en el desarrollo, construcción y operación de un centro de espectáculos ubicado
                    a un costado de la plaza comercial Pabellón Rosarito en la ciudad de Rosarito, Baja California. En
                    particular el polígono del Proyecto tiene un área ~ 4 has, en la fase 1 de ~2 has se realizarán las
                    adecuaciones necesarias para operar un centro de espectáculos al aire libre, incluyendo recinto
                    ferial y escenario de conciertos con una capacidad de 10,000 a 15,000 personas. La fase 2 incluye
                    una arena de espectáculos múltiples tipo redondel, cerrado, con gradería, módulo de baños, puestos
                    gastronómicos y bebidas y amplio estacionamiento.
                </p>
                <p>
                    El desarrollo del proyecto incluye la obtención de permisos, ejecución de contratos, realización de
                    Proyecto Ejecutivo, estudios preliminares, asignación de obra y búsqueda de financiamiento.
                </p>
                <p>
                    La construcción incluye movimientos de tierras del Proyecto, despalme, relleno y nivelación,
                    construcción de superficie final de pisos terminados a nivel de proyecto, drenaje pluvial,
                    construcción muros y cercos perimetrales (divisiones de áreas), acceso al recinto, módulo de
                    taquillas, construcción de módulos de sanitarios, red agua potable, red de drenaje sanitario,
                    instalaciones eléctricas e iluminación, construcción de escenario. Dentro de los alcances también el
                    Proyecto incluye la procura de equipo para la operación del Proyecto, lo cual incluye mobiliario,
                    generadores eléctricos, carpas, entre otros.
                </p>
                <p>
                    En cuanto a la operación el centro de espectáculos será operado por la empresa desarrolladora y
                    estará disponible para eventos de conciertos, espectáculos culturales, espectáculos familiares y
                    eventos de temporada.
                </p>
            </v-col>
        </v-row>
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-btn outlined
                       @click="gotoSection('#contact')"
                       @click.native="scrollToId('contact')">
                    <span class="font-weight-regular text-caption">Contáctenos</span>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "RosaritoPage",
    methods: {
        gotoSection(toHash) {
            this.$router.push({path: '/homepage', hash: toHash});
        },
        scrollToId(id) {
            document.getElementById(id).scrollIntoView();
            // window.scrollBy(0, -60);
            window.scrollBy({
                top: -60,
                left: 0,
                behavior: 'smooth'
            });
        },
    }
}
</script>

<style scoped>

</style>